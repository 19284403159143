import React from 'react'
import CriadorTemplates from './CriadorTemplates';

const EditorTemplates: React.FC = () => {

  return (
    <div>
      <>
        <CriadorTemplates />
      </>
    </div>
  );
};

export default EditorTemplates;